<template>
  <v-app :id="$vuetify.breakpoint.width < 1024 ? 'inspire-mobile' : ''">
    <div class="wrap">
      <router-view />
      <FooterLayout :isAuth="true" />
    </div>
  </v-app>
</template>

<script>
import webStorage from '@/utils/webStorage';
import FooterLayout from "@/components/footer/empty-footer.vue";
export default {
  components: { FooterLayout },
  mounted() {
    if (["/auth/", "/auth", "/auth/login"].includes(this.$route.fullPath)) {
      if (webStorage.getToken() || localStorage.getItem('chunkAuthObj')) {
        return this.$router.push({ path: '/' });
      }
      return this.$router.push({path: "/auth/login"}).catch(() => {});
    }
    // if(localStorage.getItem('language')) this.$i18n.locale = localStorage.getItem('language')
    const urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
    const locale = urlParams.get('locale');
    if(locale) {
      this.$i18n.locale = locale
    } else {
      if (localStorage.getItem('language')) this.$i18n.locale = localStorage.getItem('language');
    }
  },
};
</script>
