import i18n from '@/lang/i18n';
import moment from 'moment';
export const DASHBOARD_TYPES = {
  duration: 0,
  month   : 1,
};

export const DASHBOARD_DISPLAY_UNIT = {
  tCO2: 1,
  kgCO2: 2,
  tCO2Text: 't-CO2',
  kgCO2Text: 'kg-CO2',
  market: 1,
  location: 2,
};

export const defaultEmissionByCategory = {
  scope1: {
    1: {
      icon   : './img/icons/scope-1/c1.svg',
      title  : i18n.t("register_data.title_scope_1_category_1"),
      value  : 0,
      default: true,
    },
  },
  scope2: {
    2: {
      icon   : './img/icons/scope-2/c1.svg',
      title  : i18n.t("list_emissions.button_scope_2"),
      value  : 0,
      default: true,
    },
  },
  scope3: {
    3 : {
      icon      : './img/icons/scope-3/c1.svg',
      iconPrefix: './img/icons/scope-3/f-1.svg',
      title     : i18n.t("register_data.title_scope_3_category_1"),
      value     : 0,
      default   : true,
    },
    4 : {
      icon      : './img/icons/scope-3/c2.svg',
      iconPrefix: './img/icons/scope-3/f-2.svg',
      title     : i18n.t("register_data.title_scope_3_category_2"),
      value     : 0,
      default   : true,
    },
    5 : {
      icon      : './img/icons/scope-3/c3.svg',
      iconPrefix: './img/icons/scope-3/f-3.svg',
      title     : i18n.t("register_data.title_scope_3_category_3"),
      value     : 0,
      default   : true,
    },
    6 : {
      icon      : './img/icons/scope-3/c4.svg',
      iconPrefix: './img/icons/scope-3/f-4.svg',
      title     : i18n.t("register_data.title_scope_3_category_4"),
      value     : 0,
      default   : true,
    },
    7 : {
      icon      : './img/icons/scope-3/c5.svg',
      iconPrefix: './img/icons/scope-3/f-5.svg',
      title     : i18n.t("register_data.title_scope_3_category_5"),
      value     : 0,
      default   : true,
    },
    8 : {
      icon      : './img/icons/scope-3/c6.svg',
      iconPrefix: './img/icons/scope-3/f-6.svg',
      title     : i18n.t("register_data.title_scope_3_category_6"), 
      value     : 0,
      default   : true,
    },
    9 : {
      icon      : './img/icons/scope-3/c7.svg',
      iconPrefix: './img/icons/scope-3/f-7.svg',
      title     : i18n.t("register_data.title_scope_3_category_7"),
      value     : 0,
      default   : true,
    },
    10: {
      icon      : './img/icons/scope-3/c8.svg',
      iconPrefix: './img/icons/scope-3/f-8.svg',
      title     : i18n.t("register_data.title_scope_3_category_8"),
      value     : 0,
      default   : true,
    },
    11: {
      icon      : './img/icons/scope-3/c9.svg',
      iconPrefix: './img/icons/scope-3/f-9.svg',
      title     : i18n.t("register_data.title_scope_3_category_9"),
      value     : 0,
      default   : true,
    },
    12: {
      icon      : './img/icons/scope-3/c10.svg',
      iconPrefix: './img/icons/scope-3/f-10.svg',
      title     : i18n.t("register_data.title_scope_3_category_10"),
      value     : 0,
      default   : true,
    },
    13: {
      icon      : './img/icons/scope-3/c11.svg',
      iconPrefix: './img/icons/scope-3/f-11.svg',
      title     : i18n.t("register_data.title_scope_3_category_11_export"),
      value     : 0,
      default   : true,
    },
    14: {
      icon      : './img/icons/scope-3/c12.svg',
      iconPrefix: './img/icons/scope-3/f-12.svg',
      title     : i18n.t("register_data.title_scope_3_category_12_export"),
      value     : 0,
      default   : true,
    },
    15: {
      icon      : './img/icons/scope-3/c13.svg',
      iconPrefix: './img/icons/scope-3/f-13.svg',
      title     : i18n.t("register_data.title_scope_3_category_13"),
      value     : 0,
      default   : true,
    },
    16: {
      icon      : './img/icons/scope-3/c14.svg',
      iconPrefix: './img/icons/scope-3/f-14.svg',
      title     : i18n.t("register_data.title_scope_3_category_14"),
      value     : 0,
      default   : true,
    },
    17: {
      icon      : './img/icons/scope-3/c15.svg',
      iconPrefix: './img/icons/scope-3/f-15.svg',
      title     : i18n.t("register_data.title_scope_3_category_15"),
      value     : 0,
      default   : true,
    },
    18: {
      icon      : './img/icons/scope-3/c16.svg',
      iconPrefix: './img/icons/scope-3/f-16.svg',
      title     : i18n.t("list_emissions.title_emissions_others"),
      value     : 0,
      default   : true,
    },
  },
  scope4: {
    19: {
      icon   : './img/icons/scope-4/c2.svg',
      title  : i18n.t("list_emissions.button_emissions_reduction_certificed"),
      value  : 0,
      default: true,
    },
  },
};

export const mappingCategoryRowNumToScope = {
  // scope 1
  1: 'scope1',
  // scope 2
  2: 'scope2',
  // scope 3
  3 : 'scope3',
  4 : 'scope3',
  5 : 'scope3',
  6 : 'scope3',
  7 : 'scope3',
  8 : 'scope3',
  9 : 'scope3',
  10: 'scope3',
  11: 'scope3',
  12: 'scope3',
  13: 'scope3',
  14: 'scope3',
  15: 'scope3',
  16: 'scope3',
  17: 'scope3',
  18: 'scope3',
  // scope 4
  19: 'scope4',
};

export const defaultEmissionByPeriodWithYearly = () => {
  const now = moment();

  let start  = now.clone().subtract(3, 'year');
  const data = [];

  for (let i = 0; i < 3; i++) {
    data.push({
      time_range     : `${start.clone().add(i, 'year').format('YYYY/MM')} - ${start
        .clone()
        .add(i + 1, 'year')
        .format('YYYY/MM')}`,
      emission_scope1: 0,
      emission_scope2: 0,
      emission_scope3: 0,
      emission_scope4: 0,
    });
  }

  return data;
};

export const defaultEmissionByPeriodWithMonthly = () => {
  const now = moment();

  let start  = now.clone().subtract(5, 'month');
  const data = [];

  for (let i = 0; i < 5; i++) {
    data.push({
      time_range     : `${start.clone().add(i, 'month').format('YYYY/MM')}`,
      emission_scope1: 0,
      emission_scope2: 0,
      emission_scope3: 0,
      emission_scope4: 0,
    });
  }

  return data;
};

const COUNT_MONTHS = 100;
const START_YEAR   = 2019;

export const defaultDurationEmissionByCategory = () => {
  let year = START_YEAR;

  return Array.from(Array(COUNT_MONTHS)).map((_, key) => {
    const month = ((key + 4) % 12) + 1;

    if (month === 1) {
      year++;
    }

    return {
      year,
      month,
    };
  });
};

export const FILTER_DATA = {
  ORGANIZATIONAL: 'organizational_select',
  COMPANY       : 'company_select',
  COUNTRY       : 'country_select',
  BUSINESS      : 'business_select',
  LAYER_3       : 'layer3_select',
  LAYER_4       : 'layer4_select',
  LAYER_5       : 'layer5_select',
  LAYER_6       : 'layer6_select',
}

export const ORGANIZATIONAL_DATA = {
  1: i18n.t("product_dashboard_main.label_unit"),
  2: i18n.t("product_dashboard_main.label_sub_company"),
  3: i18n.t("product_dashboard_main.label_affiliated_company"),
  4: i18n.t("product_dashboard_main.label_other_affiliated_company"),
}

export const TEXT_NULL = i18n.t("register_data.checkbox_blank");
export const BLANK_OPTION_TEXT =  i18n.t("product_dashboard_main.label_no_base_setting");
export const PERIOD_DASHBOARD = {
  PERIOD_YEAR: 'py',
  PERIOD_HALF_YEAR: 'phy',
  PERIOD_QUARTER_YEAR: 'pqy',
  PERIOD_MONTH_YEAR: 'pmy',
}
export const UNIT_SETTING = {
  CO2: 'co2',
  PERCENT: 'percent',
  CURRENCY: 'currency',
}

export const SEPARATORS = {
  0: { thousandSeparator: ',', decimalSeparator: '.' },
  1: { thousandSeparator: '.', decimalSeparator: ',' },
  2: { thousandSeparator: "'", decimalSeparator: '.' },
  3: { thousandSeparator: " ", decimalSeparator: ',' },
};