<template>
  <div v-if="isMobile" class="footer footer-sp" :class="fullScreenClass">
    <v-row class="logo-wrapper">
      <div class="logo logo-app-sp" @click="goToHome">
        <img class="logo-img" src="@/assets/icons/footer/logo.svg" alt="" />
        <div class="version">{{ versions }}</div>
      </div>
      <div class="to-top-sp">
        <img @click="handleToTop" class="logo-img back-to-top" src="@/assets/icons/to-top.svg" alt="" />
      </div>
    </v-row>
    <v-row class="footer-content-sp">
      <v-col>
        <p v-for="(item, index) in spList" :key="index">
          <a
            :href="item.href"
            target="_blank"
            class="router-footer"
          >
            {{item.label}}
          </a>
        </p>
      </v-col>
    </v-row>
    <div class="hr"></div>
    <v-row class="footer-end-sp"> ©︎2022 Sustech Inc. </v-row>
  </div>
  <div v-else class="footer footer-pc" :class="fullScreenClass">
    <v-row class="logo-wrapper">
      <div class="logo logo-app-pc" @click="goToHome">
        <img class="logo-img" src="@/assets/icons/footer/logo.svg" alt="" />
        <div class="version">{{ versions }}</div>
      </div>
      <div class="to-top-pc">
        <span class="text-to-top-pc"> PAGE TOP </span>
        <img @click="handleToTop" class="toTop-leave logo-img back-to-top" src="@/assets/icons/to-top.svg" alt="" />
        <img
          @click="handleToTop"
          class="toTop-hover logo-img back-to-top"
          src="@/assets/icons/to-top-leave.svg"
          alt=""
        />
      </div>
    </v-row>
    <v-row class="footer-title-pc">
      <v-col>
        <p>Tools</p>
      </v-col>
      <v-col>
        <p>Settings</p>
      </v-col>
      <v-col>
        <p>Others</p>
      </v-col>
    </v-row>
    <div class="hr-short"></div>
    <v-row class="footer-content-pc">
      <v-col>
        <p v-for="(item, index) in toolsList" :key="index">
          <a
            :href="item.href"
            @click.prevent="negative(item.click)"
            class="router-footer"
          >
            {{item.label}}
          </a>
        </p>
      </v-col>
      <v-col>
        <p v-for="(item, index) in settingList" :key="index">
          <a
            :href="item.href"
            @click.prevent="negative(item.click)"
            class="router-footer"
          >
            {{item.label}}
          </a>
        </p>
        <div @click="logout()"><p class="router-footer-end">{{$t("footer.hyperlink_logout")}}</p></div>
      </v-col>
      <v-col>
        <p v-for="(item, index) in otherList" :key="index">
          <a
            :href="item.href"
            target="_blank"
            class="router-footer"
          >
            {{item.label}}
          </a>
        </p>
      </v-col>
    </v-row>
    <div class="hr last-child"></div>
    <v-footer class="footer-section">
      <v-col cols="4"></v-col>
      <v-col cols="4" class="text-center">© 2022 Sustech Inc.</v-col>
    </v-footer>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import { VERSIONS } from '@/constants/descriptions.js';
export default {
  props: {
    spList: {
      type: Array,
      default: () => [],
    },
    toolsList: {
      type: Array,
      default: () => [],
    },
    settingList: {
      type: Array,
      default: () => [],
    },
    otherList: {
      type: Array,
      default: () => [],
    },
    isSupplier: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      versions: VERSIONS
    }
  },
  computed: {
    ...mapState('registerData', ['isFullScreen']),
    isMobile() {
      return this.$vuetify.breakpoint.width < 1024;
    },
    fullScreenClass() {
      return this.isFullScreen ? 'd-none' : '';
    }
  },
  methods: {
    handleToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    goToHome() {
      return this.$emit('goToHome');
    },
    logout() {
      this.$emit('logout');
    },
    negative(url) {
      this.handleToTop();
      if (!url) {
        return;
      }
      const path = this.isSupplier ? this.$route.fullPath : this.$route.path;
      if (path === url) {
        this.$router.go();
      } else {
        console.log(url);
        
        this.$router.push({ path: url }).catch((err) => {console.warn(err);
        });
      }
    },
  }
}
</script>
<style scoped lang="scss">
@import '@/components/footer/footerStyles/index';
.footer {
  position: relative !important;
  .footer-sp {
    height: max-content;
    display: block;
    margin-top: 80px;
    .logo-wrapper {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      margin: unset;
      padding: 40px 35px 0 20px;
      .version {
        color: $monoDark;
        font-size: 12px;
        margin-top: 4px;
        cursor: default;
      }
    }
    .logo-app-sp {
      img {
        width: 140px;
        height: 18px;
      }
      display: flex;
      gap: 10px;
    }
    .logo-app-sp,
    .to-top-sp {
      display: flex;
      align-items: flex-start;
    }

    .footer-content-sp {
      color: $monoDark;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.05em;
      margin-top: 36px;
      margin-bottom: 25px;
      margin-left: 0;
      padding: 0 20px;
      .col {
        padding: unset;
        p {
          margin-bottom: 17px;
        }
      }
    }

    .footer-end-sp {
      justify-content: center;
      padding: 9px 0px 11px;
      margin: 0;
      color: #99a6a9;
      width: 100%;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 0.05em;
    }
    .hr {
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    }
}
}
</style>