import { numberFormat } from '@/filters/number';
import { formatBigNumber, math, formatValue } from '@/concerns/newRegisterData/wijmo.helper'
import { formatNumberByConditions, addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
import { getWidthYAxisByTextContent} from '@/utils/calcTextWidth';
import { UNIT_SETTING } from '@/constants/dashboard';
import { isCO2Setting } from '@/utils/pcaf';
import { DASHBOARD_DISPLAY_UNIT } from '@/constants/dashboard';
const AXIS_Y_ITEM_COUNT = 6;

export const prepareChartAxisY = (totalList, yItemCount = AXIS_Y_ITEM_COUNT, unit = 't-CO2', isNormal = false) => {
  let minVal = 0, maxVal = 0;
  let arrayList = [...[].concat(...totalList)];
  if (arrayList.every(item => item === 0)) {
    minVal = 0;
    maxVal = 0;
  } else {
    const bignumbers = arrayList.map(number => {
      if (number === '0.00') {
        return 0;
      } else {
        return math.bignumber(number)
      }
    });
    minVal = math.min(...bignumbers);
    maxVal = math.max(...bignumbers);
  }
  let chartAxisY = [];
  let min = 0, max = 0;
  let axisYStep = roundRangeNumber(formatBigNumber(math.evaluate(`(${math.abs(minVal).toString()} + ${math.abs(maxVal).toString()}) / ${yItemCount}`), 50));
  if (axisYStep === 0) {
    // condition when min = max = 0;
    axisYStep = 10;
  }
  if (math.largerEq(minVal, 0)) {
    min = 0;
    let precision = (math.larger(axisYStep, 0) && math.smaller(axisYStep, 1)) ? roundNumberSmallerThan1(axisYStep) : 2;
    max = formatBigNumber(math.evaluate(`${yItemCount} * ${axisYStep.toString()}`), precision);
  } else if (math.smallerEq(maxVal, 0)) {
    max = formatBigNumber(math.evaluate(`${axisYStep.toString()}`));
    min = formatBigNumber(math.evaluate(`-(${yItemCount - 1}) * ${axisYStep.toString()}`));
    // using do/while when minVal < min
    while (math.smaller(math.bignumber(minVal), math.bignumber(min))) {
      axisYStep = roundRangeNumber(formatBigNumber(math.evaluate(`(${math.abs(min).toString()} + ${math.abs(max).toString()} + ${axisYStep.toString()}) / ${yItemCount}`)));
      max = formatBigNumber(math.evaluate(`${axisYStep.toString()}`));
      min = formatBigNumber(math.evaluate(`-(${yItemCount - 1}) * ${axisYStep.toString()}`));
    }
  } else {
    min = formatBigNumber(math.evaluate(`-${ceilNumber(minVal, axisYStep)} * ${axisYStep.toString()}`));
    max = formatBigNumber(math.evaluate(`(${yItemCount - ceilNumber(minVal, axisYStep)}) * ${axisYStep.toString()}`));
    // using do/while when max < maxVal
    while (math.smaller(math.bignumber(max), math.bignumber(maxVal))) {
      axisYStep = roundRangeNumber(formatBigNumber(math.evaluate(`(${math.abs(min).toString()} + ${math.abs(max).toString()} + ${axisYStep.toString()}) / ${yItemCount}`)));
      min = formatBigNumber(math.evaluate(`-${ceilNumber(minVal, axisYStep)} * ${axisYStep.toString()}`));
      max = formatBigNumber(math.evaluate(`(${yItemCount - ceilNumber(minVal, axisYStep)}) * ${axisYStep.toString()}`));
    }
  }
  let i = 0;
  do {
    if (i === yItemCount) {
      chartAxisY.push({
        value: math.isInteger(max) ? max : formatBigNumber(max, 2),
        text: unit,
        percent: 96,
      });
    } else {
      let axisText = '';
      let rangeText = '';
      if (math.larger(axisYStep, 0) && math.smaller(axisYStep, 1)) {
        rangeText = formatBigNumber(math.evaluate(`${min.toString()} + (${i} * ${axisYStep.toString()})`), roundNumberSmallerThan1(axisYStep));
      } else {
        rangeText = formatBigNumber(math.evaluate(`${min.toString()} + (${i} * ${axisYStep.toString()})`));
      }
      if (math.equal(rangeText, 0)) {
        axisText = '0';
      } else if (math.larger(axisYStep, 0) && math.smaller(axisYStep, 1)) {
        axisText = formatBigNumber(math.evaluate(`${min.toString()} + (${i} * ${axisYStep.toString()})`), roundNumberSmallerThan1(axisYStep));
      } else {
        axisText = numberFormat(math.floor(math.evaluate(`${min.toString()} + (${i} * ${axisYStep.toString()})`)));
      }
      if(isNormal) {
        chartAxisY.push({
          value: formatBigNumber(math.evaluate(`${min.toString()} + (${i} * ${axisYStep.toString()})`), 50),
          text: formatYAxisSmallerThan1(axisText, unit),
          percent: 96 / yItemCount * i,
        });
      } else {
        axisText = unit === DASHBOARD_DISPLAY_UNIT.kgCO2Text ? numberFormat(math.multiply(formatValue(axisText), 1000)) : axisText;
        let value = formatBigNumber(math.evaluate(`${min.toString()} + (${i} * ${axisYStep.toString()})`), 50)
        
        chartAxisY.push({
          value: unit === DASHBOARD_DISPLAY_UNIT.kgCO2Text ? math.multiply(value, 1000) : value ,
          text: formatYAxisSmallerThan1(axisText, unit),
          percent: 96 / yItemCount * i,
        });
      }
    }
    i++;
  } while (i <= yItemCount);
  return [removeDuplicate(chartAxisY, 'value'), min, max];
};

export const calcCategoryPercent = (item, max, min) => {
  const zeroValuePercent = Math.abs(Number(min) / (max - min)) * 100;
  if(item.credit) {
    item.credit = item.credit.map(cr => {
      return {
        ...cr,
        period: (Math.abs((Number(cr.total_emission) / (max - min))) * 100).toFixed(2),
        zero_value_percent: zeroValuePercent,
        period_negative: (Math.abs((Number(item.total_emission) / (max - min))) * 100).toFixed(2),
      }
    })  
  }
  return {
    ...item,
    period: (Math.abs((Number(item.value) / (max - min))) * 100).toFixed(2),
    zero_value_percent: zeroValuePercent,
    period_negative: (Math.abs((Number(item.value) / (max - min))) * 100).toFixed(2),
  }
}

const removeDuplicate = (array, key) => {
  return array.filter((obj, index, self) => 
    index === self.findIndex((t) => (
      t[key] === obj[key]
    ))
  )
};

const roundRangeNumber = (number) => {
  if (number === "0.00") return 0;
  const convertNumberToString = number.toString();
  let axisNumber = 0;
  if (math.larger(number, 0) && math.smallerEq(number, 10)) {
    if (math.equal(number, 1) || math.equal(number, 2) || math.equal(number, 5) || math.equal(number, 10)) {
      return number;
    } else if (math.larger(number, 5)) {
      return 10;
    } else {
      if (math.larger(number, 1) && math.smaller(number, 2)) {
        return 2;
      } else if (math.larger(number, 2)) {
        return 5;
      } else { // if number like 0.0xxx
        // cut number before
        const newNumber = addThoundSandCommaWithTwoDigit(number, '', false, true);
        // get length decimal part of number
        const decimalPart = math.string(newNumber).split(".")[1];
        let indexDecimalPartDiff0 = 1;
        for (let index = 0; index < decimalPart.length; index++) {
          if (decimalPart.charAt(index) !== '0') {
            indexDecimalPartDiff0 = index;
            break;
          }
        }
        if (indexDecimalPartDiff0 === 0 && decimalPart.charAt(0) >= 5) {
          // check condition while number like 0.xxx
          return math.string(newNumber).split('.')[0] + 1;
        }
        let string = '0.';
        for (let i = 0; i < indexDecimalPartDiff0; i++) {
          string += '0';
        }

        const chartAtIndex = Number(decimalPart.charAt(indexDecimalPartDiff0));
        if (chartAtIndex === 5 || chartAtIndex === 2) {
          if (Number(decimalPart.charAt(indexDecimalPartDiff0 + 1)) === '0') {
            return `${string}${chartAtIndex}`;
          }
        }

        if (chartAtIndex >= 5) {
          axisNumber = `${string}${Number(chartAtIndex + 1)}`;
          if (indexDecimalPartDiff0 > 0) {
            // number like 0.065 => 0.1, 0.065 => 0.01
            axisNumber = `${string.slice(0, -1)}${Number(decimalPart.charAt(indexDecimalPartDiff0 - 1) + 1)}`;
          }
        } else if (chartAtIndex >= 2 && chartAtIndex < 5) {
          axisNumber = `${string}5`;
        } else {
          axisNumber = `${string}2`;
        }
      }
    }
  } else {
    const chartAt2 = Number(convertNumberToString.charAt(1));
    // get length integer part of number
    const integerPartLength = math.string(number).split(".")[0].length;
    if (chartAt2 === 5 || chartAt2 === 2) {
      if (integerPartLength === 2) {
        // number like x5 || x2
        return number;
      }
      let allZeroes = true;
      // condition check number like x500000 || x20000
      for (let index = 2; index < integerPartLength; index++) {
        if (convertNumberToString.charAt(index) !== '0') {
          allZeroes = false;
          break;
        }
      }
      if (allZeroes) return number;
    }
    if (chartAt2 >= 5) {
      axisNumber = formatBigNumber(math.evaluate(`${Number(Number(convertNumberToString.charAt(0)) + 1).toString()} * ${math.pow(10, integerPartLength - 1).toString()}`));
    } else if (chartAt2 >= 2 && chartAt2 < 5) {
      axisNumber = formatBigNumber(math.evaluate(`${Number(Number(convertNumberToString.charAt(0))).toString()} * ${math.pow(10, integerPartLength - 1).toString()} + 5 * ${math.pow(10, integerPartLength - 2).toString()}`));
    } else if (chartAt2 === 0) {
      axisNumber = formatBigNumber(math.evaluate(`${Number(Number(convertNumberToString.charAt(0))).toString()} * ${math.pow(10, integerPartLength - 1).toString()} + 1 * ${math.pow(10, integerPartLength - 2).toString()}`));
    } else {
      axisNumber = formatBigNumber(math.evaluate(`${Number(Number(convertNumberToString.charAt(0))).toString()} * ${math.pow(10, integerPartLength - 1).toString()} + 2 * ${math.pow(10, integerPartLength - 2).toString()}`));
    }
  }
  return axisNumber;
}

const ceilNumber = (value, axisYStep) => {
  const ceilNumber = math.ceil(math.abs(value).toString() / axisYStep);
  if (ceilNumber === 0) return 1;
  return ceilNumber;
}

export const prepareChartAxisYLineChart = (totalList, yItemCount = AXIS_Y_ITEM_COUNT) => {
  let minVal = 0, maxVal = 0;
  let arrayList = [...[].concat(...totalList)];
  if (arrayList.every(item => item === 0)) {
    minVal = 0;
    maxVal = 0;
  } else {
    const bignumbers = arrayList.map(number => {
      if (number === '0.00') {
        return 0;
      } else {
        return math.bignumber(number)
      }
    });
    minVal = math.min(...bignumbers);
    maxVal = math.max(...bignumbers);
  }
  if (math.equal(minVal, 0) && math.equal(maxVal, 0)) {
    return [0, yItemCount * 10 , 10];
  }
  if (math.equal(minVal, maxVal)) {
    if (math.largerEq(minVal, 0)) {
      minVal = 0;
    } else if (math.smallerEq(maxVal, 0)) {
      maxVal = 0;
    }
    
  }
  let min = 0, max = 0;
  let axisYStep = roundRangeNumber(formatBigNumber(math.evaluate(`(${math.abs(minVal).toString()} + ${math.abs(maxVal).toString()}) / ${yItemCount}`), 50));
  if (axisYStep === 0) {
    // condition when min = max = 0;
    axisYStep = 10;
  }
  if (math.largerEq(minVal, 0)) {
    min = 0;
    let precision = (math.larger(axisYStep, 0) && math.smaller(axisYStep, 1)) ? roundNumberSmallerThan1(axisYStep) : 14;
    max = formatBigNumber(math.evaluate(`${yItemCount} * ${axisYStep.toString()}`), precision);
  } else if (math.smallerEq(maxVal, 0)) {
    max = formatBigNumber(math.evaluate(`${axisYStep.toString()}`));
    min = formatBigNumber(math.evaluate(`-(${yItemCount - 1}) * ${axisYStep.toString()}`));
    // using do/while when minVal < min
    while (math.smaller(math.bignumber(minVal), math.bignumber(min))) {
      axisYStep = roundRangeNumber(formatBigNumber(math.evaluate(`(${math.abs(min).toString()} + ${math.abs(max).toString()} + ${axisYStep.toString()}) / ${yItemCount}`)));
      max = formatBigNumber(math.evaluate(`${axisYStep.toString()}`));
      min = formatBigNumber(math.evaluate(`-(${yItemCount - 1}) * ${axisYStep.toString()}`));
    }
  } else {
    min = formatBigNumber(math.evaluate(`-${ceilNumber(minVal, axisYStep)} * ${axisYStep.toString()}`));
    max = formatBigNumber(math.evaluate(`(${yItemCount - ceilNumber(minVal, axisYStep)}) * ${axisYStep.toString()}`));
    // using do/while when max < maxVal
    while (math.smaller(math.bignumber(max), math.bignumber(maxVal))) {
      axisYStep = roundRangeNumber(formatBigNumber(math.evaluate(`(${math.abs(min).toString()} + ${math.abs(max).toString()} + ${axisYStep.toString()}) / ${yItemCount}`)));
      min = formatBigNumber(math.evaluate(`-${ceilNumber(minVal, axisYStep)} * ${axisYStep.toString()}`));
      max = formatBigNumber(math.evaluate(`(${yItemCount - ceilNumber(minVal, axisYStep)}) * ${axisYStep.toString()}`));
    }
  }
  return [Number(min), Number(max), Number(axisYStep)];
}

const roundNumberSmallerThan1 = (number) => {
  let indexDecimalPartDiff0 = 1;
  const decimalPart = math.string(number).split(".")[1];
  for (let index = 0; index < decimalPart.length; index++) {
    if (decimalPart.charAt(index) !== '0') {
      indexDecimalPartDiff0 = index;
      break;
    }
  }
  return indexDecimalPartDiff0 + 1;
}
export function getMaxChartItemsCount({
  maximumColumnsToShow,
  processedData,
  indexAtFirstBar,
  chartContainerRef,
  MOBILE_WIDTH,
  getSummary,
  axisYItemsCount,
  axisYItemsSource
}) {

  if (window.innerWidth < MOBILE_WIDTH) {
    return maximumColumnsToShow;
  }
  // Step 1: Get the width of the chart container
  const widthContainer = chartContainerRef?.clientWidth || 0;

  // Step 2: Determine the maximum columns to show based on the tab
  const assumptionValues = processedData.slice(indexAtFirstBar, indexAtFirstBar + maximumColumnsToShow);

  // Calculate the minimum width of the Y axis
  const minWidthYAxis = getWidthYAxisData(assumptionValues, getSummary, axisYItemsCount, axisYItemsSource);

  // Step 3: Calculate the maximum number of chart items to display
  const offsetWidth = 80;
  const minWidthColumn = 57;
  let maxChartItem = Math.min(
    Math.floor((widthContainer - minWidthYAxis - offsetWidth) / minWidthColumn),
    maximumColumnsToShow
  );
  
  // Step 4: Check if more columns can be added without breaking the UI
  let index_tmp = 0;
  for (let i = maxChartItem; i < maximumColumnsToShow; i++) {
    const nextItem = processedData[index_tmp + indexAtFirstBar];
    index_tmp++;

    const minWidthYAxisOfCheck = getWidthYAxisData([nextItem], getSummary, axisYItemsCount, axisYItemsSource);
    const newTotalWidth = (maxChartItem + 1) * minWidthColumn + offsetWidth + Math.max(minWidthYAxis, minWidthYAxisOfCheck);

    if (newTotalWidth <= widthContainer) {
      maxChartItem += 1;
    } else {
      break;
    }
  }

  return maxChartItem;
}

const getWidthYAxisData = (assumptionValue, getSummary, axisYItemsCount, axisYItemsSource) => {
  const summary = getSummary(assumptionValue);
  const [ assumptionYAxis ] = prepareChartAxisY(summary, axisYItemsCount);
  const yAxisItem0TextLength = assumptionYAxis[0]?.text.length || 0;
  const yAxisItem7ValueLength = addThoundSandCommaWithTwoDigit(axisYItemsSource[axisYItemsCount-1]?.value.toString().length, '', false, true) || 0;
  const text = yAxisItem0TextLength > yAxisItem7ValueLength
    ? assumptionYAxis[0]?.text
    : assumptionYAxis[axisYItemsCount-1]?.text?.toString();
  const width = getWidthYAxisByTextContent(text);
  return Math.max(width, 60) + 2;
}

export const formatValueUnit = (value, isTco2Unit, isDashboardDetail = false) => {
  const valueEmission = math.bignumber(formatValue(value));
  if (isDashboardDetail) {
    return isTco2Unit ? addThoundSandCommaWithTwoDigit(valueEmission) : formatBigNumber(math.multiply(valueEmission, 1000), 50) // calculate to kg
  }
  return isTco2Unit ? formatBigNumber(valueEmission) : formatBigNumber(math.multiply(valueEmission, 1000), 50) // calculate to kg
}

export const getMinWidthYAxisChartColumn = (axisYItemsSource) => {
  let maxWidth = 0;
  axisYItemsSource.forEach((item) => {
    const textWidth = getWidthYAxisByTextContent(item?.text || '');
    if (textWidth > maxWidth) {
      maxWidth = textWidth;
    }
  });
 return maxWidth < 60 ? '60px' : maxWidth + 2 + 'px';
}

export const formatYAxisSmallerThan1 = (axisText, unit = 't-CO2') => {
  if (axisText === '-') return '0';
  if (math.larger(formatValue(axisText), -1) && math.smaller(formatValue(axisText), 1) && axisText != '0') {
      return formatNumberByConditions(axisText, { isRealNumber: true });
  } else {
      return math.equal(formatValue(axisText), 0) ? 0 : formatNumberByConditions(axisText, { formatUnit: isCO2Setting(unit) ? UNIT_SETTING.CO2 : UNIT_SETTING.CURRENCY });
  }
}