import { UNIT_SETTING, SEPARATORS } from '@/constants/dashboard';
export const state = () => ({
    isDisableMenu: false,
    settings: {
      thousandSeparator: ',',
      decimalSeparator: '.',
      tco2Decimal: 0,
      percentDecimal: 0,
      currencyDecimal: 0,
      settingDigits: 0,
    },
  });
  export const getters = {
    getThousandSeparatorBySetting: (state) => {
      return state.settings.thousandSeparator;
    },
    getDecimalSeparatorBySetting: (state) => {
      return state.settings.decimalSeparator;
    },
    getDigitsBySetting: (state) => {
      return state.settings.settingDigits;
    },
    getDecimalPlacesBySettingUnit: (state) => (unit) => {
      switch (unit) {
        case UNIT_SETTING.CO2:
          return state.settings.tco2Decimal;
        case UNIT_SETTING.PERCENT:
          return state.settings.percentDecimal;
        case UNIT_SETTING.CURRENCY:
          return state.settings.currencyDecimal;
        default:
          return state.settings.tco2Decimal;
      }
    },
  };
  export const mutations = {
    updateDisableMenu(state, newVal) {
      state.isDisableMenu = newVal
    },
    updateSettingNumber(state, newVal) {
      const { thousand_separator, co2_decimal_precision, percentage_decimal_precision, currency_decimal_precision } = newVal.setting;

      if (SEPARATORS[thousand_separator]) {
        Object.assign(state.settings, SEPARATORS[thousand_separator], {
          tco2Decimal: co2_decimal_precision,
          percentDecimal: percentage_decimal_precision,
          currencyDecimal: currency_decimal_precision,
          settingDigits  : thousand_separator
        });
      }
    }
  };
  
  export const actions = {
    actionDisableMenu({ commit }, newVal) {
      commit('updateDisableMenu', newVal)
    },
    actionSettingNumber({ commit }, newVal) {
      commit('updateSettingNumber', newVal);
    },
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
  };
  