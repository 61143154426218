export const convertQueryToString = (url, route) => {
  let params = '';
  let querySize = 0;
  for (const [key, value] of Object.entries(route.query)) {
    querySize += 1;
    if (!params.includes(key)) {
      params += (querySize === Object.keys(route.query)?.length) ? `${key}=${value}` : `${key}=${value}&`;
    }
  }
  return `${url}?${params}`;
}