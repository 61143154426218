import Vue from 'vue';
import { formatNumberRealNum } from '@/utils/convertNumber';

const numberFormat = Vue.filter('numberFormat', function (value) {
  if (value == null || value == 'undefined' || value == 0) {
    return '-';
  }

  let dicimalNumber = value % 1 != 0 ? 2 : 0
  if (value >= 0 && value <= 1) {
    let indexDecimalPartDiff0 = 1;
    const decimalPart = value.toString().split(".")[1];
    if (decimalPart) {
      for (let index = 0; index < decimalPart.length; index++) {
        if (decimalPart.charAt(index) !== '0') {
          indexDecimalPartDiff0 = index;
          break;
        }
      }
      dicimalNumber = indexDecimalPartDiff0 + 1;
      if (decimalPart.charAt(indexDecimalPartDiff0 + 1) !== '0' && decimalPart.charAt(indexDecimalPartDiff0 + 1) !== '') {
        dicimalNumber = indexDecimalPartDiff0 + 2;
      }
    }
  }
  return new Intl.NumberFormat('ja-JP', {minimumFractionDigits: dicimalNumber}).format(Number(value));
});

const toCurrency = Vue.filter('toCurrency', function (value) {
  if (value == null || value == 'undefined'  || value === '') {
    return null;
  }
  const formatValue = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 20,
  }).format(value);
  return 'NaN' !== formatValue ? formatNumberRealNum(formatValue) : formatNumberRealNum(value);
});

const isInt = (value) => {
  return Number(value) === value && value % 1 === 0;
};

export { numberFormat, isInt, toCurrency };
