const join_word = '_'
import { DB_TYPE } from '@/constants/dbType';
export const makeNewId = (id, type = 0) => btoa(unescape(encodeURIComponent([id, type].join(join_word))))

export const checkIdIsBase64 = (id) => {
  try {
    let newId = atob(id)

    return /^[\d]{1,}_[0|1]{1}$/.test(newId)
  } catch (error) {
    return false
  }
}

export const splitId = (joinId) => {
  const [id, type] = atob(joinId).split(join_word)

  return {
    id: Number(id),
    type: Number(type)
  }
}

export const prepareMasterOrCustomizeDB = (data = []) => {
  if(data.length === 0) return []
  let objIds = {}
  const responseArray = []
  for (let index = 0; index < data.length; index++) {
    const item = data[index];
    const newID = makeNewId(item.id, item.type);
    if(!objIds[newID]) {
      objIds[newID] = newID
      responseArray.push({
        ...item,
        id: item?.id_minor_processed || newID, //id_minor_processed for case have origin_id in item
        origin_id: item.id
      })
    }
  }
  return responseArray
  return (data || []).map((item) => {
    return {
      ...item,
      id: makeNewId(item.id, item.type),
      origin_id: item.id
    }
  })
}

export const getDbCustomizeOrDbMasterById= (masterDb) => {
  // let result = {}
  const mapById = new Map();

  prepareMasterOrCustomizeDB(masterDb).forEach((item) => {
    // if (!result[item.id]) {
    //   result[item.id] = {...item}
    // }
    if (!mapById.has(item.id)) {
      mapById.set(item.id, {...item});
    }
  })
  return Object.fromEntries(mapById)
  return result
}

export const getMasterDbId = (dbMasterList, fuelData) => {
  return dbMasterList.find(item => item.item_name === fuelData);
}

export const getListDbAddNew = dbExternalAdded => {
  const { SERVICE_LINK, IDEA, MASTER, CUSTOMIZE } = DB_TYPE;
  return prepareMasterOrCustomizeDB([ ...dbExternalAdded[CUSTOMIZE], ...dbExternalAdded[MASTER], ...dbExternalAdded[IDEA], ...dbExternalAdded[SERVICE_LINK]], true)
}

export const getDbAddNewInObject = (dataList, itemKey) => {
  const result = {}
  dataList.forEach(item => {
    if(!result[item.id]) {
      result[item.id] = {
        ...item,
        // id: makeNewId(item.id, item.type),
        // origin_id: item.id
      }
    }
  })

  return result
}