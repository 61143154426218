<template>
  <div>
    <Footer
      :spList="spList"
      :toolsList="toolsList"
      :settingList="settingList"
      :otherList="otherList"
      :isSupplier="true"
      @goToHome="goToHome"
      @logout="logout"
    ></Footer>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import { ROUTES } from '@/router/constants';
import Footer from '@/components/footer/index.vue';
import { convertQueryToString } from '@/utils/urlconverts';
export default {
  components: { Footer },
  computed: {
    ...mapState('userData', ['isCfpSupplier']),
    spList() {
      return [
        {
          href: "https://sustech-inc.co.jp/carbonix/",
          label: this.$t("footer.hyperlink_carbonix_official_site"),
        },
        {
          href: "https://sustech-inc.co.jp/",
          label: this.$t("footer.hyperlink_operating_company"),
        },
        {
          href: this.convertQueryToUrlString(`${ROUTES.OTHERS}/${ROUTES.TERM_OF_USER}`),
          label: this.$t("footer.hyperlink_term_of_use"),
        },
        {
          href: this.convertQueryToUrlString(`${ROUTES.OTHERS}/${ROUTES.PRIVACY}`),
          label: this.$t("footer.hyperlink_privacy_policy"),
        },
        {
          href: "https://www.truste.or.jp/hssl/validate/01632.php",
          label: this.$t("footer.hyperlink_truste_certified_page"),
        },
      ]
    },
    toolsList() {
      return [
      {
          href: this.convertQueryToUrlString(`${ROUTES.SUPPLIER}`),
          click: this.convertQueryToUrlString(`${ROUTES.SUPPLIER}`),
          label: this.isCfpSupplier ? this.$t("supplier_products.title_emission_data_submissions_form") : this.$t("supplier.hyperlink_footer_primary_submission_form"),
          enabled: true,
        },
      ];
    },
    settingList() {
      return [
      {
          href: this.convertQueryToUrlString(ROUTES.SETTING_LANGUAGE_SUPPLIER),
          click: this.convertQueryToUrlString(ROUTES.SETTING_LANGUAGE_SUPPLIER),
          label: this.$t("setting_language.title_language_settings"),
          enabled: true,
        },
      ];
    },
    otherList() {
      return [
        {
          href: "https://sustech-inc.co.jp/carbonix/",
          label: this.$t("footer.hyperlink_carbonix_official_site"),
        },
        {
          href: "https://sustech-inc.co.jp/",
          label: this.$t("footer.hyperlink_operating_company"),
        },
        {
          href: this.convertQueryToUrlString(`${ROUTES.OTHERS}/${ROUTES.TERM_OF_USER}`),
          label: this.$t("footer.hyperlink_term_of_use"),
        },
        {
          href: this.convertQueryToUrlString(`${ROUTES.OTHERS}/${ROUTES.PRIVACY}`),
          label: this.$t("footer.hyperlink_privacy_policy"),
        },
        {
          href: "https://www.truste.or.jp/hssl/validate/01632.php",
          label: this.$t("footer.hyperlink_truste_certified_page"),
        },
      ]
    },
  },
  methods: { 
    goToHome() {
      this.$router.push({ path: ROUTES.SUPPLIER, query: { primary_uuid: this.$route?.query?.primary_uuid } });
    },
    logout() {
      this.$emit('logout');
    },
    convertQueryToUrlString(url) {
      return convertQueryToString(url, this.$route)
    }
  }
}
</script>