<template>
  <div>
    <Footer
      :spList="spList"
      :toolsList="toolsList"
      :settingList="settingList"
      :otherList="otherList"
      @goToHome="goToHome"
      @logout="logout"
    ></Footer>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { ROUTES } from '@/router/constants';
import { ROLE } from '@/constants/role';
import Footer from '@/components/footer/index.vue';
export default {
  components: { Footer },
  computed: {
    ...mapState('userData', ['currentUser', 'planType', 'existDbSource']),
    isAdminRole() {
      return ROLE.ADMIN === this.currentUser?.user?.role_id;
    },
    isApprovalRole() {
      return ROLE.APPROVAL === this.currentUser?.user?.role_id;
    },
    isUserRole() {
      return ROLE.USER === this.currentUser?.user?.role_id;
    },
    spList() {
      return [
        {
          href: "https://sustech-inc.co.jp/carbonix/",
          label: this.$t("footer.hyperlink_carbonix_official_site"),
        },
        {
          href: process.env.VUE_APP_FAQS_LINK,
          label: this.$t("footer.hyperlink_faqs"),
        },
        {
          href: "https://sustech-inc.co.jp/",
          label: this.$t("footer.hyperlink_operating_company"),
        },
        {
          href: `${ROUTES.OTHERS}/${ROUTES.TERM_OF_USER}`,
          label: this.$t("footer.hyperlink_term_of_use"),
        },
        {
          href: `${ROUTES.OTHERS}/${ROUTES.PRIVACY}`,
          label: this.$t("footer.hyperlink_privacy_policy"),
        },
        {
          href: "https://www.truste.or.jp/hssl/validate/01632.php",
          label: this.$t("footer.hyperlink_truste_certified_page"),
        },
      ]
    },
    toolsList() {
      return [
        {
          href: `${ROUTES.DASHBOARD}`,
          click: `${ROUTES.DASHBOARD}`,
          label: this.$t("footer.hyperlink_dashboard"),
          enabled: true,
        },
        {
          href: `${ROUTES.PRODUCT_REDUCTION}`,
          click: `${ROUTES.PRODUCT_REDUCTION}`,
          label: this.$t("footer.hyperlink_reduction_target"),
          enabled: !this.isApprovalRole && !this.isUserRole && this.planType?.is_reduction_target,
        },
        {
          href: `${ROUTES.VIEW_EMISSION}/1/1`,
          click: `${ROUTES.VIEW_EMISSION}/1/1`,
          label: this.$t("footer.hyperlink_emissions_list"),
          enabled: true,
        },
        {
          href: `${ROUTES.EMISSIONS}`,
          click: ROUTES.EMISSIONS,
          label: this.$t("footer.hyperlink_registration"),
          enabled: true,
        },
        {
          href: `${ROUTES.LIBRARY_BASIC_UNIT}`,
          click: ROUTES.LIBRARY_BASIC_UNIT,
          label: this.$t("basic_unit_library.label_title"),
          enabled: true,
        },
        {
          href: `${ROUTES.EMISSIONS}/${ROUTES.LIST_EMISSION_TEMPLATES}`,
          click: `${ROUTES.EMISSIONS}/${ROUTES.LIST_EMISSION_TEMPLATES}`,
          label: this.$t("footer.hyperlink_template"),
          enabled: true,
        },
        {
          href: `${ROUTES.EMISSIONS}/${ROUTES.LIST_EMISSION_SUBMITTED}`,
          click: `${ROUTES.EMISSIONS}/${ROUTES.LIST_EMISSION_SUBMITTED}`,
          label: this.$t("footer.hyperlink_submit_status"),
          enabled: true,
        },
        {
          href: `${ROUTES.APPROVAL}`,
          click: ROUTES.APPROVAL,
          label: this.$t("footer.hyperlink_approve"),
          enabled: !this.isUserRole,
        },
        {
          href: `${ROUTES.PRIMARY_DATA_MERGE}`,
          click: ROUTES.PRIMARY_DATA_MERGE,
          label: this.$t("footer.hyperlink_primary_data_merge"),
          enabled: !this.isUserRole && this.planType?.is_primary_data,
        },
        {
          href: `${ROUTES.OTHERS}/${ROUTES.OFFSET_TRANSACTION}`,
          click: `${ROUTES.OTHERS}/${ROUTES.OFFSET_TRANSACTION}`,
          label: this.$t("footer.hyperlink_offset_transaction"),
          enabled: true,
        },
        {
          href: `${ROUTES.EXPORT_REPORT}`,
          click: ROUTES.EXPORT_REPORT,
          label: this.$t("footer.hyperlink_report_export"),
          enabled: true,
        },
      ].filter(item => item.enabled === true);
    },
    settingList() {
      return [
        {
          href: `${ROUTES.SETTING}`,
          click: ROUTES.SETTING,
          label: this.$t("footer.hyperlink_setting_top"),
          enabled: true,
        },
        {
          href: `${ROUTES.USER_MANAGEMENT}`,
          click: ROUTES.USER_MANAGEMENT,
          label: this.$t("footer.hyperlink_user_management"),
          enabled: this.isAdminRole,
        },
        {
          href: `${ROUTES.FACILITY_MANAGEMENT}`,
          click: ROUTES.FACILITY_MANAGEMENT,
          label: this.$t("footer.hyperlink_facility_management"),
          enabled: this.isAdminRole,
        },
        {
          href: `${ROUTES.VIEWER_MANAGEMENT}`,
          click: ROUTES.VIEWER_MANAGEMENT,
          label: this.$t("footer.hyperlink_view_authority_management"),
          enabled: this.isAdminRole,
        },
        {
          href: `${ROUTES.CALCULATION_TERM}`,
          click: ROUTES.CALCULATION_TERM,
          label: this.$t("footer.hyperlink_year_setting"),
          enabled: this.isAdminRole,
        },
        {
          href: `${ROUTES.REDUCTION_TARGET_LIST_MANAGEMENT}`,
          click: ROUTES.REDUCTION_TARGET_LIST_MANAGEMENT,
          label: this.$t("footer.hyperlink_reduction_list"),
          enabled: this.isAdminRole && this.planType?.is_reduction_target,
        },
        {
          href: `${ROUTES.CREATE_DATA_CUSTOMIZE}`,
          click: ROUTES.CREATE_DATA_CUSTOMIZE,
          label: this.$t("footer.hyperlink_customized_db_management"),
          enabled: true,
        },
        {
          href: this.DbSouceLink,
          click: this.DbSouceLink,
          label: this.$t("idea_db_source.hyperlink_db_source"),
          enabled: this.isAdminRole,
        },
        {
          href: ROUTES.SETTING_AUTO_APPROVAL,
          click: ROUTES.SETTING_AUTO_APPROVAL,
          label: this.$t("setting.button_setting_auto_approval"),
          enabled: !this.isUserRole,
        },
        {
          href: `${ROUTES.SETTING_LANGUAGE}`,
          click: ROUTES.SETTING_LANGUAGE,
          label: this.$t("setting.button_setting_language"),
          enabled: true,
        },
        {
          href: `${ROUTES.CHANGE_PASSWORD_USER}`,
          click: ROUTES.CHANGE_PASSWORD_USER,
          label: this.$t("footer.hyperlink_change_password"),
          enabled: true,
        },
        {
          href: `${ROUTES.GROUP_ENTERPRISE_MANAGEMENT}`,
          click: ROUTES.GROUP_ENTERPRISE_MANAGEMENT,
          label: this.$t("group_enterprise_management.button_enterprise_management_setting"),
          enabled: this.isAdminRole && this.planType?.is_pcaf,
        },
        {
          href: `${ROUTES.LINKED_SERVICE_MANAGEMENT}`,
          click: ROUTES.LINKED_SERVICE_MANAGEMENT,
          label: this.$t("linked_service_management.button_linked_service_management_setting"),
          enabled: this.isAdminRole,
        },
        {
          href: `${ROUTES.CSV_FORMAT}`,
          click: ROUTES.CSV_FORMAT,
          label: this.$t("footer.hyperlink_csv_format"),
          enabled: true,
        },
        {
          href: `${ROUTES.SETTING_DISPLAY_FORMAT_NUMBER}`,
          click: ROUTES.SETTING_DISPLAY_FORMAT_NUMBER,
          label: this.$t("setting_format_number.button_setting_format_number_on_setting"),
          enabled: true,
        },
      ].filter(item => item.enabled === true);
    },
    otherList() {
      return [
        {
          href: "https://sustech-inc.co.jp/carbonix/",
          label: this.$t("footer.hyperlink_carbonix_official_site"),
        },
        {
          href: process.env.VUE_APP_FAQS_LINK,
          label: this.$t("footer.hyperlink_faqs"),
        },
        {
          href: "https://sustech-inc.co.jp/",
          label: this.$t("footer.hyperlink_operating_company"),
        },
        {
          href: `${ROUTES.OTHERS}/${ROUTES.TERM_OF_USER}`,
          label: this.$t("footer.hyperlink_term_of_use"),
        },
        {
          href: `${ROUTES.OTHERS}/${ROUTES.PRIVACY}`,
          label: this.$t("footer.hyperlink_privacy_policy"),
        },
        {
          href: "https://www.truste.or.jp/hssl/validate/01632.php",
          label: this.$t("footer.hyperlink_truste_certified_page"),
        },
        {
          href: `${ROUTES.OTHERS}/${ROUTES.VERSIONS}`,
          label: this.$t("footer.hyperlink_versions"),
        },
      ]
    },
    DbSouceLink() {
      return this.existDbSource ? ROUTES.LIST_IDEA_DB_SOURCE : ROUTES.REGISTER_DB_SOURCE;
    }
  },
  methods: { 
    goToHome() {
      this.$router.push({ path: '/' });
    },
    logout() {
      this.$emit('logout');
    },
  }
}
</script>